import React from 'react'
// import Img from 'gatsby-image'

export default function ImageBanner (props) {
  return (
    <>
      <div className="image-card">
        <div className="float-icon">
          <img src={props.icon} className={`rounded-circle ${props.bgColor}`} />
        </div>
        <img src={props.cardImage} />
      </div>
    </>
  )
}
// export default BannerCloudFrontQuery
