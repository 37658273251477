import React from 'react'
import '../../scss/base/_carousel.scss'

export default function videoCarousel (props) {
  return (
    <>
      <video autoPlay loop muted>
        <source src={props.video} type={props.type} />
      </video>
    </>
  )
}
