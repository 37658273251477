import React, { useState } from 'react'
import './styles/show-case.scss'
import '../scss/base/_buttons.scss'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { Carousel } from 'react-responsive-carousel'
import { useStaticQuery, graphql } from 'gatsby'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

// Componentes
import Banner from '../components/Banner/banner'
import CardImage from '../components/CardImage/index'
import CardService from '../components/CardService/index'
import ImageCarousel from '../components/Carousel/imageCarousel'
import VideoCarousel from '../components/Carousel/videoCarousel'
import CardBlog from '../components/blog/card/blogCard'

// assets
import IconText from '../components/IconText/index'
import Gifhome from '../images/gifs/gifhome.gif'
// import Videohome from '../images/gifs/videoshome.mp4'
import img from '../images/samples/natureza.jpg'
import cardImg from '../images/samples/shutterstock_719542471.webp'
import logo from '../images/icon/testeIcon.webp'

const DarkBlueSection = () => (
  <>
    <h3 className="text-center mt-5">Section Full Azul</h3>
    <section className="bg-lwart-blue full">
      <div className="container py-4">
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
      </div>
    </section>
  </>
)

const BgGradientBlue = () => (
  <>
    <h3 className="text-center mt-5"> Section w/ Gradient Blue to Green - Left</h3>
    <section className="bg-blue-gradient-left full">
      <div className="container py-4">
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
      </div>
    </section>
  </>
)

const SectionWithDivGradient = () => (
  <>
    <h3 className="text-center mt-5"> Section w/ Border-bottom gradient</h3>
    <section className="bg-lwart-darkblue full">
      <div className="container py-4">
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
      </div>
      <div className="gradient-blue-to-green"></div>
    </section>
  </>
)

const Gradient1 = () => (
  <>
    <h3 className="text-center py-3">Gradiente Verde para o Azul</h3>
    <div className="gradient-green-to-blue"></div>
  </>
)

const Gradient2 = () => (
  <>
    <h3 className="text-center py-3">Gradiente Verde para o Azul Escuro</h3>
    <div className="gradient-green-to-darkblue"></div>
  </>
)

const Gradient3 = () => (
  <>
    <h3 className="text-center py-3">Gradiente Azul para o Azul escuro</h3>
    <div className="gradient-blue-to-darkblue"></div>
  </>
)

const Gradient4 = () => (
  <>
    <h3 className="text-center py-3">Gradiente Azul para o Verde</h3>
    <div className="gradient-blue-to-green"></div>
  </>
)

const SectionParalax = () => (
  <>
    <h3 className="text-center py-3"> Paralax </h3>
    <section className="full">
      <div className="bg-paralax"></div>
    </section>
  </>
)

const ComponentSection = () => (
  <>
    <DarkBlueSection></DarkBlueSection>
    <BgGradientBlue></BgGradientBlue>
    <SectionWithDivGradient></SectionWithDivGradient>
    <SectionParalax></SectionParalax>
    <Gradient1></Gradient1>
    <Gradient2></Gradient2>
    <Gradient3></Gradient3>
    <Gradient4></Gradient4>
  </>
)

const ImageCard = () => (
  <div className="container">
    <div className="row d-flex justify-content-center">
      <div className="col-md-6">
        <h3 className="text-center mb-5">Image-Card w/ blue background</h3>
        <CardImage icon={logo} cardImage={cardImg} bgColor={'bg-lwart-blue'}></CardImage>
      </div>

      <div className="col-md-6">
        <h3 className="text-center mb-5">Image-Card w/ green background</h3>
        <CardImage icon={logo} cardImage={cardImg} bgColor={'bg-lwart-green'}></CardImage>
      </div>
    </div>
  </div>
)

const ServiceCard = () => (
  <div className="container">
    <header className="my-5">
      <h2 className="text-lwart-darkblue text-center">Nossos serviços e produtos</h2>
    </header>
    <div className="row">
      <div className="row pt-5">
        <div className="col-md-4">
          <CardService icon={logo} cardImage={cardImg} bgColor={'bg-lwart-blue'}
            title="Coleta de Óleo Lubrificante Usado"
            text="Atendemos a mais de 3 mil municípios, garantindo a coleta e o rerrefino de toneladas de óleo usado com qualidade, segurança e agilidade.">
          </CardService>
        </div>
        <div className="col-md-4">
          <CardService icon={logo} cardImage={cardImg} bgColor={'bg-lwart-green'}
            title="Óleo Básico de Alta Performance"
            text="Uma linha de produtos rerrefinados com altíssimo grau de pureza com especificações que atendem aos mais exigentes requisitos das indústrias de lubrificantes.">
          </CardService>
        </div>
        <div className="col-md-4">
          <CardService icon={logo} cardImage={cardImg} bgColor={'bg-lwart-green2'}
            title="Gestão de Resíduos Sólidos"
            text="Uma solução ambiental completa de recolhimento, destinação e transformação de resíduos contaminados com óleo lubrificante. Mantendo nosso alto padrão de serviço, atendimento e responsabilidade ambiental.">
          </CardService>
        </div>
      </div>
    </div>
  </div>
)

const BlogCard = () => {
  const data = useStaticQuery(
    graphql`{
  card1: file(relativePath: {eq: "home/cards/card-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  card2: file(relativePath: {eq: "home/cards/card-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  card3: file(relativePath: {eq: "home/cards/card-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
  )
  return (
    <div className="container">
      <header className="my-5">
        <h2 className="text-lwart-darkblue text-center">Blog Card</h2>
      </header>
      <div className="row">
        <div className="col-md-4">
          <CardBlog
            image={data.card1.childImageSharp.gatsbyImageData}
            description={'When it comes to design, this blog has everything you need to create beautiful posts that will grab your reader\'s attention. Check out ou...'}
            title={'Nossa tecnologia de hidrotratamento'}
            author={'Time Lwart'}
            readTime={'1 min'}
            date={'Aug 6'}
          />
        </div>
        <div className="col-md-4">
          <CardBlog
            image={data.card2.childImageSharp.gatsbyImageData}
            description={'When it comes to design, this blog has everything you need to create beautiful posts that will grab your reader\'s attention. Check out ou...'}
            title={'Nossa tecnologia de hidrotratamento'}
            author={'Time Lwart'}
            readTime={'1 min'}
            date={'Aug 6'}
          />
        </div>
        <div className="col-md-4">
          <CardBlog
            image={data.card3.childImageSharp.gatsbyImageData}
            description={'When it comes to design, this blog has everything you need to create beautiful posts that will grab your reader\'s attention. Check out ou...'}
            title={'Nossa tecnologia de hidrotratamento'}
            author={'Time Lwart'}
            readTime={'1 min'}
            date={'Aug 6'}
          />
        </div>
      </div>
    </div>
  )
}

const ComponentCards = () => (
  <>
    <ImageCard />
    <ServiceCard />
    <BlogCard />
  </>
)

const CarouselPequeno = () => (
  <>
    <div className="container" id="small-carousel">
      <div className="text-center"><h2>Carousel Pequeno (Responsive Carousel)</h2></div>
      <Carousel showStatus={false} infiniteLoop={true}>
        <div className="w-100 small-carousel-item">
          <VideoCarousel video={'https://d29gz1j979b5he.cloudfront.net/videoshome.mp4'} type="video/mp4" />
        </div>
        <div>
          <ImageCarousel image={Gifhome} alt="Gif" />
        </div>
        <div>
          <ImageCarousel image={cardImg} alt="Imagem teste" />
        </div>
      </Carousel>
    </div>
  </>
)

const CarouselHome = () => (
  <>
    <div id="homeCarousel">
      <div className="text-center"><h2>Carousel Home (Responsive Carousel)</h2></div>
      <Carousel showStatus={false} infiniteLoop={true}>
        <VideoCarousel video={'https://d29gz1j979b5he.cloudfront.net/videoshome.mp4'} type="video/mp4" />
        <VideoCarousel video={'https://d29gz1j979b5he.cloudfront.net/videoshome.mp4'} type="video/mp4" />
      </Carousel>
    </div>
  </>
)

const CarouselReactIdSwiper = () => {
  const params = {
    autoplay: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30
  }
  return (
    <>
      <div className="text-center"><h2>Carousel Small (Swiper-id-React)</h2></div>
      <div className="container">
        <div id="small-carousel-swipper">
          <Swiper {...params}>
            <div className="carousel-sm-swipper-item">
              <VideoCarousel video={'https://d29gz1j979b5he.cloudfront.net/videoshome.mp4'} type="video/mp4" />
            </div>
            <div className="carousel-sm-swipper-item">
              <VideoCarousel video={'https://d29gz1j979b5he.cloudfront.net/videoshome.mp4'} type="video/mp4" />
            </div>
          </Swiper>
        </div>
      </div>
      <div className="mt-5">
        <div className="text-center"><h2>Carousel Home (Swiper-id-React)</h2></div>
        <div id="carousel-swipper">
          <Swiper {...params}>
            <div className="carousel-swipper-item">
              <VideoCarousel video={'https://d29gz1j979b5he.cloudfront.net/videoshome.mp4'} type="video/mp4" />
            </div>
            <div className="carousel-swipper-item">
              <VideoCarousel video={'https://d29gz1j979b5he.cloudfront.net/videoshome.mp4'} type="video/mp4" />
            </div>
          </Swiper>
        </div>
      </div>
    </>
  )
}
const ComponentCarousel = () => (
  <>
    <CarouselPequeno />
    <CarouselHome />
    <CarouselReactIdSwiper />
  </>
)

const ButtonsPadrao = () => (
  <>
    <div className="container">
      <div className="text-center mb-2 mt-2">Buttons</div>
      <div className="row justify-content-center pt-2 pb-2" style={{ backgroundColor: '#D2B48C' }}>
        <button type="button" className="btn btn-buttonWhite">ButtonWhite</button>
        <button type="button" className="btn btn-primary">Primary</button>
        <button type="button" className="btn btn-secondary">Secondary</button>
        <button type="button" className="btn btn-success">Success</button>
        <button type="button" className="btn btn-danger">Danger</button>
        <button type="button" className="btn btn-warning">Warning</button>
        <button type="button" className="btn btn-info">Info</button>
        <button type="button" className="btn btn-light">Light</button>
        <button type="button" className="btn btn-dark">Dark</button>
        <button type="button" className="btn btn-link">Link</button>
      </div>
      <div className="text-center mb-2 mt-2">Buttons Outline</div>
      <div className="row justify-content-center pt-2 pb-2" style={{ backgroundColor: '#D2B48C' }}>
        <button type="button" className="btn btn-outline-buttonWhite">ButtonWhite</button>
        <button type="button" className="btn btn-outline-primary">Primary</button>
        <button type="button" className="btn btn-outline-secondary">Secondary</button>
        <button type="button" className="btn btn-outline-success">Success</button>
        <button type="button" className="btn btn-outline-info">Info</button>
        <button type="button" className="btn btn-outline-warning">Warning</button>
        <button type="button" className="btn btn-outline-danger">Danger</button>
        <button type="button" className="btn btn-outline-dark">Dark</button>
        <button type="button" className="btn btn-outline-light text-dark">Light</button>
      </div>
      <div className="text-center mb-2 mt-2">Buttons Size
        <div className="justify-content-center">
          <button type="button" className="btn btn-primary btn-lg">Large</button>
          <button type="button" className="btn btn-primary">Default</button>
          <button type="button" className="btn btn-primary btn-sm">Small</button>
        </div>
      </div>

    </div>
  </>
)

const TextIcon = () => (
  <>
    <div className="container">
      <div className="row justify-content-center mb-2 mt-5" style={{ backgroundColor: '#D2B48C' }}>
        <div className="mr-2">
          <IconText icon={logo} text="Meio Ambiente" bgColor="bg-lwart-blue" />
        </div>
        <div className="mr-2">
          <IconText icon={logo} text="Impacto Social" bgColor="bg-lwart-green" />
        </div>
        <div className="mr-2">
          <IconText icon={logo} text="Desenvolvimento" bgColor="bg-lwart-green2" />
        </div>
      </div>
    </div>
  </>
)

const ComponentButtons = () => (
  <>
    <ButtonsPadrao />
    <TextIcon />
  </>
)

const ShowCase = () => {
  return (
    <>
      <Banner image={img}></Banner>
      <Tabs></Tabs>
      <div></div>
    </>
  )
}

export default ShowCase
// export default injectIntl(ShowCase)

const Tabs = () => {
  const [active, setActive] = useState(0)
  const components = [
    <ComponentSection></ComponentSection>,
    <ComponentCards></ComponentCards>,
    <ComponentCarousel></ComponentCarousel>,
    <ComponentButtons></ComponentButtons>
  ]
  return (
    <>
      <section>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6 d-flex justify-content-center">
            <div className="col-lg-3 col-3">
              <button className={active === 0 ? 'btn shadow' : 'btn'} onClick={() => setActive(0)}>Section</button>
            </div>
            <div className="col-lg-3 col-3">
              <button className={active === 1 ? 'btn shadow' : 'btn'} onClick={() => setActive(1)}>Cards</button>
            </div>
            <div className="col-lg-3 col-3">
              <button className={active === 2 ? 'btn shadow' : 'btn'} onClick={() => setActive(2)}>Carousel</button>
            </div>
            <div className="col-lg-3 col-3">
              <button className={active === 3 ? 'btn shadow' : 'btn'} onClick={() => setActive(3)}>Buttons</button>
            </div>
          </div>
        </div>
      </section>
      {
        components.map((component, i) => (active === i && component))
      }
    </>
  )
}
