import React from 'react'

export default function IconText (props) {
  return (
    <>
      <div className="iconText">
        <div className="text-center">
          <img className={`rounded-circle ${props.bgColor} mb-2`} src={props.icon} draggable={false} alt="Icon" />
          <p>{props.text}</p>
        </div>
      </div>
    </>
  )
}
