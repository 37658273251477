import React from 'react'
import '../../scss/base/_carousel.scss'

export default function imageCarousel (props) {
  return (
    <>
      <div className="small-carousel-item">
        <img src={props.image} alt={props.alt} className="w-100" />
      </div>
    </>
  )
}
