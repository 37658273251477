import React from 'react'
import Img from 'gatsby-image'
import './banner.scss'

export default function Banner (props) {
  return (
    <>
      <section className="full simple-banner">
        <div className="position-relative d-grid text-center align-items-center">
          <div className="hero w-100 position-absolute px-5">
            <h1 className="line-height-normal letter-space-normal mb-3 text-white">{props.title}</h1>
            <p className="font-size-125 line-height-normal px-5 mx-md-5 font-weight-bold  text-white">{props.subTitle}</p>
          </div>
          <div className="w-100">
            <Img fluid={props.image} alt="Banner" />
          </div>
        </div>
      </section>
    </>
  )
}
