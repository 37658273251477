import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function CardService (props) {
  return <>
    <div className="service-card mt-5">
      <div className="float-icon">
        <img src={props.icon} className={`rounded-circle ${props.bgColor}`} alt="Imagem Icones Card Serviços" />
      </div>
      <GatsbyImage image={props.cardImage} className="w-100" style={{ height: '200px' }} />
      <div className="card border-0 bg-transparent p-0">
        <div className="card-body">
          <span className="text-lwart-darkblue font-Spinnaker">{props.title}</span>
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  </>
}
